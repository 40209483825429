<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Hilton Yucatan Layout',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/hilton-yucatan/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/hilton-yucatan/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/hilton-yucatan/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/hilton-yucatan/favicons/manifest.json', type: 'application/json' },
]

if (!isVoixTemplatizer)
  setupHeader('f1c748e5-8ed2-46df-aee5-1ffc70f24022', links)

const {
  resorts,
  brands,
  currentResort,
  footerCompanyMenu,
  footerResourcesMenu,
} = await getCommonLayoutData(isVoixTemplatizer)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#8e2395' })
provide('currentResort', currentResort.value.data)
provide('resorts', resorts.value.data)
provide('brands', brands.value.data)

const route = useRoute()
const isHomepage = computed(() => route.path === '/the-yucatan-resort-playa-del-carmen')
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white hilton-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />

        <div id="playa-content">
          <NavigationHeader
            v-if="!isVoixTemplatizer"
            :config="{
              stacked: false,
              logoPosition: 'left', // left or center
              menuPosition: 'right', // right or center
              backgroundClass: 'bg-transparent',
              bookNowBackground: 'bg-[#8e2395]',
              bookNowText: 'text-white',
              textClass: isHomepage ? 'text-white' : 'text-gray-500',
              noSpacer: isHomepage,
              scrollTriggerDistance: 100,
              scrollBooking: false,
              scrollTextClass: 'text-gray-500',
              scrollBackgroundClass: 'bg-white',
              paper: false,
            }"
          >
            <template #logo="logoProps">
              <img v-if="logoProps.distanceTriggered || !isHomepage" src="https://playa-cms-assets.s3.amazonaws.com/media/logos/2021/the-yucatan-logo-black.png" width="145" alt="Hilton Yucatan Logo">
              <img v-else src="https://playa-cms-assets.s3.amazonaws.com/media/logos/2021/the-yucatan-logo-white.png" width="145" alt="Hilton Yucatan Logo">
            </template>
          </NavigationHeader>
          <slot />

          <NavigationFooter
            v-if="!isVoixTemplatizer"
            :footer-company-menu="footerCompanyMenu?.data"
            :footer-resources-menu="footerResourcesMenu?.data"
            :config="{
              crossPromotion: false,
              signUpForm: false,
              termsMenuPosition: 'center', // left or center
            }"
          >
            <template #bottomSlot>
              <div class="flex justify-center">
                <div class="text-center pt-4">
                  <img src="@/assets/imgs/footer/Tap-logo.png">
                </div>
              </div>
            </template>
          </NavigationFooter>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/hilton-yucatan.scss';
</style>
